import React from 'react'
import { string } from 'prop-types'

import bbfcFaviconIco from '../../../asset/meta/bbfc/favicon.ico'
import bbfcFavicon16 from '../../../asset/meta/bbfc/favicon-16.png'
import bbfcFavicon32 from '../../../asset/meta/bbfc/favicon-32.png'
import bbfcAppleTouchIcon76 from '../../../asset/meta/bbfc/apple-touch-icon-76.png'
import bbfcAppleTouchIcon120 from '../../../asset/meta/bbfc/apple-touch-icon-120.png'
import bbfcAppleTouchIcon152 from '../../../asset/meta/bbfc/apple-touch-icon-152.png'
import bbfcAppleTouchIcon180 from '../../../asset/meta/bbfc/apple-touch-icon-180.png'
import bbfcIcon192 from '../../../asset/meta/bbfc/icon-192.png'

import cbbfcFaviconIco from '../../../asset/meta/cbbfc/favicon.ico'
import cbbfcFavicon16 from '../../../asset/meta/cbbfc/favicon-16.png'
import cbbfcFavicon32 from '../../../asset/meta/cbbfc/favicon-32.png'
import cbbfcAppleTouchIcon76 from '../../../asset/meta/cbbfc/apple-touch-icon-76.png'
import cbbfcAppleTouchIcon120 from '../../../asset/meta/cbbfc/apple-touch-icon-120.png'
import cbbfcAppleTouchIcon152 from '../../../asset/meta/cbbfc/apple-touch-icon-152.png'
import cbbfcAppleTouchIcon180 from '../../../asset/meta/cbbfc/apple-touch-icon-180.png'
import cbbfcIcon192 from '../../../asset/meta/cbbfc/icon-192.png'

const themeMeta = {
  bbfc: {
    faviconIco: bbfcFaviconIco,
    favicon16: bbfcFavicon16,
    favicon32: bbfcFavicon32,
    icon192: bbfcIcon192,
    appleIconSizes: {
      76: bbfcAppleTouchIcon76,
      120: bbfcAppleTouchIcon120,
      152: bbfcAppleTouchIcon152,
      180: bbfcAppleTouchIcon180
    }
  },
  cbbfc: {
    faviconIco: cbbfcFaviconIco,
    favicon16: cbbfcFavicon16,
    favicon32: cbbfcFavicon32,
    icon192: cbbfcIcon192,
    appleIconSizes: {
      76: cbbfcAppleTouchIcon76,
      120: cbbfcAppleTouchIcon120,
      152: cbbfcAppleTouchIcon152,
      180: cbbfcAppleTouchIcon180
    }
  }
}

const GlobalMeta = ({
  googleSiteVerificationCode,
  title,
  themeColor,
  theme
}) => {
  const optional = {
    'application-name': title,
    'theme-color': themeColor,
    'google-site-verification': googleSiteVerificationCode
  }

  const {
    faviconIco,
    favicon16,
    favicon32,
    icon192,
    appleIconSizes
  } = themeMeta[theme]

  return (
    <>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />
      <meta
        name="facebook-domain-verification"
        content="9mjalm722m4uusj7nv4c1k47mzy2vt"
      />

      <link rel="stylesheet" href="https://use.typekit.net/xsy7ryg.css" />
      <link rel="shortcut icon" href={faviconIco} />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      <link rel="icon" sizes="192x192" href={icon192} />

      {Object.keys(appleIconSizes).map((size) => (
        <link
          key={`appleTouchIcon${size}`}
          rel="apple-touch-icon"
          sizes={`${size}x${size}`}
          href={appleIconSizes[size]}
        />
      ))}

      {Object.keys(optional).map((name) => {
        if (!optional[name]) return
        return (
          <meta key={`Optional${name}`} name={name} content={optional[name]} />
        )
      })}
    </>
  )
}

GlobalMeta.propTypes = {
  googleSiteVerificationCode: string,
  title: string.isRequired,
  themeColor: string.isRequired,
  theme: string.isRequired
}

export default GlobalMeta
