import React from 'react'
import { string } from 'prop-types'

import SmartLink from '@/component/Primitive/SmartLink'
import Icon from '@/component/Primitive/Icon'

const SocialLink = ({ url, type, text }) => (
  <SmartLink
    href={url}
    key={`${type}-icon`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <Icon type={type} width={24} height={24} a11yText={type} />
    {text}
  </SmartLink>
)

SocialLink.propTypes = {
  type: string,
  text: string,
  url: string
}

export default SocialLink
