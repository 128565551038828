import React from 'react'
import { string, oneOf, array } from 'prop-types'
import colors from '@/shape/colors'
import classNames from 'classnames'

import Type from '@/component/Primitive/Type'

import styles from './Themes.module.scss'
import RatingColor from '@/component/Common/RatingColor'

const Themes = ({ themes, shortFormInsight, color, className, rating }) => (
  <RatingColor
    className={classNames(className, styles.Theme, color && styles[color])}
    property="color"
    rating={rating}
  >
    <Type size="base-large">{shortFormInsight || themes.join(', ')}</Type>
  </RatingColor>
)

Themes.defaultProps = {
  themes: []
}

Themes.propTypes = {
  themes: array,
  shortFormInsight: string,
  color: oneOf(colors),
  rating: string,
  className: string
}

export default Themes
