const createQuery = () => `
query Autocomplete($url: String!, $searchTerm: String!) {
  autocomplete(url: $url, searchTerm:$searchTerm) {
    results {
      classification
      title
      type
      mediaType
      releaseDate
      releaseYear
      shortFormInsight
      type
      slug
    }
  }
}

`

export default createQuery
