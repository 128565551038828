import React, { useEffect, useState } from 'react'
import { node, string } from 'prop-types'

const TagContext = React.createContext()

const TagContextProvider = ({ children, gtmId }) => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(
    typeof window !== 'undefined'
      ? window.localStorage.getItem('cookiesEnabled')
      : false
  )

  const initialiseTagManager = async () => {
    const { default: TagManager } = await import('react-gtm-module')
    TagManager.initialize({
      gtmId
    })
  }

  const enableAnalytics = () => {
    window.localStorage.setItem('cookiesEnabled', true)
    setAnalyticsEnabled(true)
  }

  useEffect(() => {
    if (analyticsEnabled) {
      initialiseTagManager()
    }
  }, [analyticsEnabled]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TagContext.Provider value={{ analyticsEnabled, enableAnalytics }}>
      {children}
    </TagContext.Provider>
  )
}

TagContextProvider.propTypes = {
  children: node.isRequired,
  gtmId: string.isRequired
}

const TagContextConsumer = TagContext.Consumer
const useTagContext = () => React.useContext(TagContext)

export { TagContext, TagContextProvider, TagContextConsumer, useTagContext }
