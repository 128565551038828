import React from 'react'

// Hook that returns currently pressed key

const useKeyPress = () => {
  const [keyPressed, setKeyPressed] = React.useState(undefined)

  const downHandler = React.useCallback((e) => {
    // Prevents input cursor from moving when navigating suggestions
    if (e.key === 'ArrowUp' || e.key === 'Up') e.preventDefault()
    if (e.key === 'ArrowDown' || e.key === 'Down') e.preventDefault()
    setKeyPressed(e.key)
  }, [])

  const upHandler = React.useCallback((e) => {
    setKeyPressed(undefined)
  }, [])

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [downHandler, upHandler])

  return keyPressed
}

export default useKeyPress
