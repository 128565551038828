import React from 'react'
import { func, bool } from 'prop-types'
import { useRatingContext } from '@/component/Context/RatingContext'

import Type from '@/component/Primitive/Type'
import Container from '@/component/Primitive/Container'
import SmartLink from '@/component/Primitive/SmartLink'
import IconButton from '@/component/Primitive/IconButton'
import AgeRating from '@/component/Primitive/AgeRating'
import RatingColor from '@/component/Common/RatingColor'

import styles from './RatingsDetail.module.scss'
import { useRouter } from 'next/router'

const detailedRatings = {
  U: {
    title: 'U Universal – Suitable for all',
    title2: 'Universal – Suitable for all',
    standfirst: 'What does the U age rating mean?',
    body: [
      'U-rated content is suitable for audiences of all ages, although not all U-rated content is aimed at children.',
      'Children’s content should be set within a positive framework and should offer reassuring counterbalances to any violence, threat or scary scenes. Generally a U is unlikely to unsettle a four-year-old, although it is impossible to predict what might affect any particular child.'
    ]
  },
  PG: {
    title: 'PG Parental Guidance',
    title2: 'Parental Guidance',
    standfirst: 'What does the PG age rating mean?',
    body: [
      'General viewing, but some scenes may be unsuitable for young children.',
      'PG-rated content is suitable for general viewing. A PG should generally not unsettle a child aged around eight, although parents and caregivers should be aware that some scenes may be unsuitable for more sensitive children.'
    ]
  },
  '12': {
    title: '12 – Suitable for 12 years and over',
    title2: 'Suitable for 12 years and over',
    standfirst: 'What does the 12 age rating mean?',
    body: [
      'Video content classified 12 contains material that is not generally suitable for children aged under 12.',
      'No one younger than 12 may rent or buy a 12-rated video.'
    ]
  },
  '12A': {
    title: '12A – Suitable for 12 years and over',
    title2: 'Suitable for 12 years and over',
    standfirst: 'What does the 12A age rating mean?',

    body: [
      'Cinema content classified 12A contains material that is not generally suitable for children aged under 12.',
      'No one younger than 12 may be permitted to attend a 12A cinema screening unless they are accompanied by an adult. Adults planning to take a child under 12 to a 12A should consider whether the main feature is suitable for that child. To help them decide, we recommend that they check the BBFC’s content advice for the film in advance.'
    ]
  },
  '15': {
    title: '15 – Suitable only for 15 years and over',
    title2: 'Suitable only for 15 years and over',
    standfirst: 'What does the 15 age rating mean?',
    body: [
      'No one younger than 15 may see 15-rated content in a cinema.',
      'No one younger than 15 may rent or buy a 15-rated video.'
    ]
  },
  '18': {
    title: '18 – Suitable only for adults',
    title2: 'Suitable only for adults',
    standfirst: 'What does the 18 age rating mean?',
    body: [
      'No one younger than 18 may see 18-rated content in a cinema.',
      'No one younger than 18 may rent or buy an 18-rated video.'
    ]
  }
}

const RatingsDetail = ({ closeHandler, layoutV2 }) => {
  const contextRating = useRatingContext()
  const router = useRouter()
  if (layoutV2) {
    return (
      <div className={styles.RatingsDetailV2}>
        <AgeRating size={4} />
        <RatingColor property="color">
          <Type className={styles.Title} size="display2">
            {detailedRatings[contextRating].title2}
          </Type>
        </RatingColor>
        <p className={styles.RatingStandfirst}>
          {detailedRatings[contextRating].standfirst}
        </p>
        <div className={styles.Description}>
          {detailedRatings[contextRating].body.map((paragraph, index) => (
            <div key={index}>{paragraph}</div>
          ))}
        </div>
        <div>
          <SmartLink
            className={styles.LinkV2}
            onClick={() => {
              const preFixPath = '/rating/'

              let path = preFixPath + contextRating

              if (contextRating === '12A') {
                path = preFixPath + '12'
              }

              router.push(path)
              closeHandler()
            }}
          >
            Learn more
          </SmartLink>
        </div>
      </div>
    )
  }
  return (
    <div className={styles.RatingsDetail}>
      <Container size="large" center gutter>
        <div className={styles.Wrapper}>
          <AgeRating size={2} className={styles.Rating} />
          <RatingColor property="color">
            <Type className={styles.Title} size="display3">
              {detailedRatings[contextRating].title}
            </Type>
          </RatingColor>
        </div>
        <Type className={styles.Body} size="base-large">
          {detailedRatings[contextRating].body.map((paragraph, index) => (
            <div key={index}>{paragraph}</div>
          ))}
        </Type>

        <RatingColor property="color">
          <IconButton
            a11yText="close"
            icon="close"
            className={styles.Close}
            onClick={() => closeHandler()}
          />
        </RatingColor>

        <Type size="base-large">
          <SmartLink
            className={styles.Link}
            to={`/rating/${contextRating === '18' ? '18' : '[rating]'}`}
            as={`/rating/${contextRating === '12A' ? '12' : contextRating}`}
          >
            Learn more
          </SmartLink>
        </Type>
      </Container>
    </div>
  )
}

RatingsDetail.propTypes = {
  closeHandler: func,
  layoutV2: bool
}

export default RatingsDetail
