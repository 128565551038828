import classNames from 'classnames'
import React from 'react'
import styles from '../BBFCHeaderV2.module.scss'
import BackIcon from '@/asset/img/redesign24/back.svg'
import { object, func, bool, array } from 'prop-types'

const MobileNav = ({
  data,
  setSelectedSubMenu,
  setToggleRatingInfo,
  setSelectedRating,
  setToggleMenu,
  toggleMenu,
  selectedSubMenu,
  handleKeyDown
}) => {
  return (
    <>
      {toggleMenu && (
        <div className={classNames(styles.MenuContainer, styles.Mobile)}>
          {data
            .map((nav) => nav.name)
            .map((nav, index) => (
              <button
                onClick={() => {
                  setSelectedSubMenu(data.find((item) => item.name === nav))
                  setToggleRatingInfo(false)
                  setSelectedRating(null)
                  setToggleMenu(false)
                }}
                className={styles.NavItem}
                key={index}
              >
                {nav}
              </button>
            ))}
        </div>
      )}
      {selectedSubMenu && (
        <div className={classNames(styles.SelectedMenuWrap, styles.Mobile)}>
          <button
            onClick={() => {
              setSelectedSubMenu(null)
              setToggleMenu(true)
            }}
          >
            <span className={styles.SubNavMenuHeading}>
              <img src={BackIcon} alt="Back" />
              <span className={styles.SelectedMenuTitle}>
                {selectedSubMenu.name}
              </span>
            </span>
          </button>
          <div>
            {selectedSubMenu.items.map((groupItem, groupIndex) => (
              <div
                className={styles.SubNavMenuContainer}
                key={`groupItem--${groupIndex}`}
              >
                {groupItem.map((item, itemIndex) => (
                  <a
                    className={classNames(
                      styles.SubNavItem,
                      item.displayType === 'header' && styles.HeaderItem,
                      item.type === 'label' && styles.LabelItem,
                      item.type.includes('Link') && styles.LinkItem,
                      item.type === 'section' && styles.SectionItem
                    )}
                    onClick={() => {
                      setToggleMenu(false)
                      setSelectedSubMenu(null)
                    }}
                    href={item.ctaLink}
                    key={`item--${itemIndex}`}
                    onKeyDown={handleKeyDown}
                  >
                    {item.ctaLabel}
                  </a>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
MobileNav.propTypes = {
  data: array,

  selectedSubMenu: object,
  setToggleRatingInfo: func,
  setSelectedRating: func,
  setSelectedSubMenu: func,
  handleKeyDown: func,
  setToggleMenu: func,
  toggleMenu: bool
}
export default MobileNav
