import React from 'react'
import { node } from 'prop-types'

import { EventEmitter } from 'events'

const EmitterContext = React.createContext()

const EmitterContextProvider = ({ children }) => {
  const emitter = new EventEmitter()
  return (
    <EmitterContext.Provider value={emitter}>
      {children}
    </EmitterContext.Provider>
  )
}

EmitterContextProvider.propTypes = {
  children: node.isRequired
}

const EmitterContextConsumer = EmitterContext.Consumer
const useEmitterContext = () => React.useContext(EmitterContext)

export {
  EmitterContext,
  EmitterContextProvider,
  EmitterContextConsumer,
  useEmitterContext
}
