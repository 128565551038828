import React from 'react'
import { node, bool } from 'prop-types'

import styles from './Main.module.scss'
import classNames from 'classnames'
import { useThemeContext } from '@/component/Context/ThemeContext'

const Main = ({ children, groupedWidgetsLayout }) => {
  const theme = useThemeContext()

  return (
    <main
      id="content"
      role="main"
      className={classNames(
        styles.Main,
        theme === 'bbfc' && styles.BBFC,
        groupedWidgetsLayout && 'groupedWidgetsLayout'
      )}
    >
      {children}
    </main>
  )
}

Main.propTypes = {
  children: node.isRequired,
  groupedWidgetsLayout: bool
}

export default Main
