import React from 'react'
import styles from './BBFCFooter.module.scss'
import links from '../../links'
import { useThemeContext } from '@/component/Context/ThemeContext'
import List from '@/component/Primitive/List'
import SmartLink from '@/component/Primitive/SmartLink'
import LogoImage from '@/asset/img/redesign24/Logo--white.svg'
import InstaLogo from '@/asset/img/redesign24/Insta.svg'
import FBLogo from '@/asset/img/redesign24/FB.svg'
import TwitterLogo from '@/asset/img/redesign24/Twitter.svg'
import LinkedInLogo from '@/asset/img/redesign24/LinkedIn.svg'
import classNames from 'classnames'

import Icon from '@/component/Primitive/Icon'
const socialMediaList = [
  { url: 'https://twitter.com/BBFC', logo: TwitterLogo },
  {
    url: 'https://www.facebook.com/BritishBoardFilmClassification',
    logo: FBLogo
  },
  { url: 'https://www.instagram.com/bbfc_ageratings', logo: InstaLogo },
  {
    url:
      'https://www.linkedin.com/company/bbfc-british-board-of-film-classification-/?originalSubdomain=uk',
    logo: LinkedInLogo
  }
]
const BBFCFooter24 = () => {
  const theme = useThemeContext()
  const scrollToTopHandle = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className={styles.Footer}>
      <button className={styles.MobileToTopBtn} onClick={scrollToTopHandle}>
        Back to the top ↑
      </button>
      <div className={styles.MainFooter}>
        <div className={styles.LogoCol}>
          <div className={styles.BBFCLogo}>
            <img src={LogoImage} alt="Logo" />
          </div>

          <div
            className={classNames(styles.SocialMediaList, styles.DesktopFooter)}
          >
            {socialMediaList.map((item, index) => (
              <SmartLink
                href={item.url}
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.SocialMediaItem}
              >
                <img alt="Logo" src={item.logo} />
              </SmartLink>
            ))}
          </div>
        </div>
        <div className={styles.LinkLists}>
          <List>
            {links[theme][0].map((link, i) => (
              <SmartLink
                key={i}
                className={styles.Link}
                as={link.url}
                to="/section"
              >
                {link.text}
              </SmartLink>
            ))}
          </List>

          <List>
            <SmartLink
              className={classNames(styles.Link, styles.UnderscoreLink)}
              href="mailto:helpline@bbfc.co.uk"
            >
              helpline@bbfc.co.uk
            </SmartLink>
            <SmartLink
              className={classNames(styles.Link, styles.UnderscoreLink)}
              href="mailto:press@bbfc.co.uk"
            >
              press@bbfc.co.uk
            </SmartLink>
            <SmartLink
              className={classNames(styles.Link, styles.UnderscoreLink)}
              href="tel:02074400299"
            >
              02074400299
            </SmartLink>
          </List>
          <List>
            <SmartLink
              className={styles.Link}
              as="/terms-and-conditions"
              to="/section"
            >
              Terms & conditions
            </SmartLink>
            <SmartLink
              className={styles.Link}
              as="/privacy-statement"
              to="/section"
            >
              Privacy policy
            </SmartLink>
          </List>
        </div>
        <button className={styles.DesktopToTopBtn} onClick={scrollToTopHandle}>
          <Icon type="up-arrow" a11yText="Back to the top" />
        </button>
        <div
          className={classNames(styles.SocialMediaList, styles.MobileFooter)}
        >
          {socialMediaList.map((item, index) => (
            <SmartLink
              href={item.url}
              key={index}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.SocialMediaItem}
            >
              <img alt="Logo" src={item.logo} />
            </SmartLink>
          ))}
        </div>
      </div>
      <div className={styles.SubFooter}>
        <div className={styles.SubFooterWrap}>
          <SmartLink className={styles.SubFooterLink} href="/">
            © {new Date().getFullYear()} BBFC
          </SmartLink>
          {links[theme][0].map((link, i) => (
            <SmartLink className={styles.SubFooterLink} key={i} href={link.url}>
              {link.text}
            </SmartLink>
          ))}
        </div>
      </div>
    </footer>
  )
}

export default BBFCFooter24
