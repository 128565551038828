import React from 'react'
import classNames from 'classnames'
import { node } from 'prop-types'
import { useDarkContext } from '@/component/Context/DarkContext'

import styles from './SiteContainer.module.scss'

const SiteContainer = ({ children }) => {
  const isDark = useDarkContext()
  return (
    <div className={classNames(styles.SiteContainer, isDark && styles.isDark)}>
      {children}
    </div>
  )
}

SiteContainer.propTypes = {
  children: node.isRequired
}

export default SiteContainer
