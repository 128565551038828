import React from 'react'
import { DarkContextProvider } from './DarkContext'
import { TagContextProvider } from './TagContext'
import { EmitterContextProvider } from '@/component/Context/EmitterContext'
import { ThemeContextProvider } from '@/component/Context/ThemeContext'

const composeProviders = (...Providers) => (Child) => (props) =>
  Providers.reduce((acc, Provider) => {
    return (
      <Provider {...props} key={Provider.name}>
        {acc}
      </Provider>
    )
  }, <Child {...props} />)

const Container = ({ children }) => children

const ContextProvider = composeProviders(
  ({ gtmId, children }) => (
    <TagContextProvider gtmId={gtmId} children={children} />
  ),
  ({ isDark, children }) => (
    <DarkContextProvider isDark={isDark} children={children} />
  ),
  ({ theme, children }) => (
    <ThemeContextProvider theme={theme} children={children} />
  ),
  EmitterContextProvider
)(Container)

export default ContextProvider
