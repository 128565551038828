import React from 'react'
import { bool, object, func } from 'prop-types'
import classNames from 'classnames'

// import UserButton from '@/component/Common/UserButton'
import SmartLink from '@/component/Primitive/SmartLink'

import styles from '../theme/BBFCNavigation.module.scss'

const Navigation = ({ isActive, user, instance, onClick }) => (
  <div className={classNames(styles.Navigation, isActive && styles.isActive)}>
    <SmartLink
      className={classNames(styles.NavigationLink, styles.bold)}
      to="/section"
      as="/about-us"
      onClick={onClick}
    >
      About us
    </SmartLink>
    <SmartLink
      className={classNames(styles.NavigationLink, styles.bold)}
      to="/section"
      as="/about-classification"
      onClick={onClick}
    >
      Classification
    </SmartLink>
    <SmartLink
      className={classNames(styles.NavigationLink, styles.bold)}
      to="/section"
      as="/education"
      onClick={onClick}
    >
      Education
    </SmartLink>
    <SmartLink
      className={classNames(styles.NavigationLink, styles.bold)}
      to="/section"
      as="/industry-services"
      onClick={onClick}
    >
      Industry
    </SmartLink>
    {/* <UserButton user={user} instance={instance} /> */}
  </div>
)

Navigation.propTypes = {
  isActive: bool,
  user: object,
  instance: object,
  onClick: func
}

export default Navigation
