import React from 'react'
import classNames from 'classnames'
import { bool, string } from 'prop-types'

import styles from './ResponsiveSpacer.module.scss'

const ResponsiveSpacer = ({ small, theme }) => {
  return (
    <div
      className={classNames(
        styles.ResponsiveSpacer,
        small && styles.small,
        theme && styles[theme]
      )}
    />
  )
}

ResponsiveSpacer.propTypes = {
  small: bool,
  theme: string
}

export default ResponsiveSpacer
