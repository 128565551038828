import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './BBFCHeaderV2.module.scss'
import IconButton from '@/component/Primitive/IconButton'
import Logo from '@/asset/img/bbfc-logo--strapline.svg'
import classNames from 'classnames'

import DesktopNav from './DesktopNav/DesktopNav'
import MobileNav from './DesktopNav/MobileNav'

import Search from '@/component/Common/Search'
import AgeRatingCarousel from '@/component/Common/AgeRatingCarousel'

const BBFCHeaderV2 = (props) => {
  const data = props.navigationItems

  const [toggleMenu, setToggleMenu] = useState(false)
  const [selectedSubMenu, setSelectedSubMenu] = useState(null)
  const [selectedRating, setSelectedRating] = useState(null)
  const [toggleRatingInfo, setToggleRatingInfo] = useState(false)
  const [toggleSearch, setToggleSearch] = useState(false)
  const [toggleSearchModal, setToggleSearchModal] = useState(false)
  const isActive =
    toggleMenu || selectedSubMenu || toggleRatingInfo || selectedRating
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Escape') {
      setToggleMenu(false)
      setSelectedSubMenu(null)
      setSelectedRating(null)
      setToggleRatingInfo(false)
      setToggleSearch(false)
    }
  }, [])
  const handleMobileToggleSearch = () => {
    setToggleMenu(false)
    setSelectedSubMenu(null)
    setSelectedRating(null)
    setToggleRatingInfo(false)
    if (toggleSearch) {
      setToggleSearch(false)
    } else {
      setToggleSearch(true)
    }
  }
  const handleMobileMenu = () => {
    setToggleSearch(false)
    setSelectedSubMenu(null)
    setSelectedRating(null)
    setToggleRatingInfo(false)

    if (!toggleMenu) {
      setToggleMenu(true)
    }

    if (selectedSubMenu) {
      setToggleMenu(false)
      setSelectedSubMenu(null)
    }
    if (toggleMenu) {
      setToggleMenu(false)
    }
  }

  return (
    <>
      <header
        className={classNames(styles.Header, isActive && styles.ActiveHeader)}
      >
        <div className={classNames(styles.NavBar)}>
          <div className={styles.LeftNavBar}>
            <div className={styles.AgeRatingCarousel}>
              <AgeRatingCarousel />
            </div>

            <a href="/" className={styles.Logo}>
              <img src={Logo} alt="BBFC Logo" />
            </a>
          </div>

          <div
            className={classNames(styles.SearchBarDesktop)}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              setToggleSearchModal(true)
            }}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
          >
            <Search
              designV2
              desktopStyle
              setToggleSearchModal={setToggleSearchModal}
              onSubmit={() => setToggleSearchModal(false)}
            />
          </div>

          <div className={styles.MobileToggleSearchInputIcon}>
            <IconButton
              onClick={handleMobileToggleSearch}
              icon={toggleSearch ? 'close' : 'search'}
              a11yText={toggleSearch ? 'close' : 'search'}
            />
            <IconButton
              onClick={handleMobileMenu}
              className={styles.MenuButton}
              icon={isActive ? 'close' : 'menu'}
              a11yText={
                toggleMenu || selectedSubMenu
                  ? 'Hide navigation'
                  : 'Reveal navigation'
              }
            />
          </div>
        </div>

        {/* This search is for mobile */}
        {toggleSearch && (
          <div
            className={classNames(styles.SearchBar, styles.SearchBarMobile)}
            tabIndex={0}
            role="button"
            onKeyDown={handleKeyDown}
          >
            <Search designV2 onSubmit={() => setToggleSearch(false)} />
          </div>
        )}
        <MobileNav
          data={data}
          toggleMenu={toggleMenu}
          selectedSubMenu={selectedSubMenu}
          setSelectedRating={setSelectedRating}
          setSelectedSubMenu={setSelectedSubMenu}
          setToggleMenu={setToggleMenu}
          setToggleRatingInfo={setToggleRatingInfo}
          handleKeyDown={handleKeyDown}
        />
        <DesktopNav
          data={data}
          selectedRating={selectedRating}
          selectedSubMenu={selectedSubMenu}
          toggleRatingInfo={toggleRatingInfo}
          setToggleRatingInfo={setToggleRatingInfo}
          setSelectedRating={setSelectedRating}
          setSelectedSubMenu={setSelectedSubMenu}
          handleKeyDown={handleKeyDown}
        />
      </header>
      <div
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        onClick={() => {
          setSelectedSubMenu(null)
          setToggleMenu(false)
          setToggleRatingInfo(false)
          setSelectedRating(null)
          setToggleSearch(false)
          setToggleSearchModal(false)
        }}
        className={classNames(styles.Overlay, isActive && styles.ActiveOverlay)}
      />

      {/* This overlay for search in desktop */}
      {toggleSearchModal && (
        <>
          <div
            onKeyDown={handleKeyDown}
            tabIndex={0}
            role="button"
            className={styles.SearchModal}
            onClick={() => setToggleSearchModal(false)}
          />
        </>
      )}
    </>
  )
}

BBFCHeaderV2.propTypes = {
  navigationItems: PropTypes.array
}

export default BBFCHeaderV2
