import React from 'react'
import styles from '../BBFCHeaderV2.module.scss'
import classNames from 'classnames'
import Icon from '@/component/Primitive/Icon'
import { RatingContextProvider } from '@/component/Context/RatingContext'
import RatingsDetail from '../../RatingsDetail'
import { object, string, func, array, bool } from 'prop-types'

const ratingOptions = ['U', 'PG', '12A', '12', '15', '18']

const DesktopNav = ({
  data,
  selectedRating,
  selectedSubMenu,
  setToggleRatingInfo,
  setSelectedRating,
  setSelectedSubMenu,
  handleKeyDown,
  toggleRatingInfo
}) => {
  return (
    <>
      <div className={styles.MenuContainerWrap}>
        <div className={classNames(styles.MenuContainer, styles.Desktop)}>
          {data
            .map((nav) => nav.name)
            .map((nav, index) => {
              const activeItem = selectedSubMenu && selectedSubMenu.name === nav
              return (
                <button
                  onKeyDown={handleKeyDown}
                  onClick={() => {
                    if (selectedSubMenu?.name === nav) {
                      setSelectedSubMenu(null)
                    } else {
                      setSelectedSubMenu(data.find((item) => item.name === nav))
                    }
                    setToggleRatingInfo(false)
                    setSelectedRating(null)
                  }}
                  className={classNames(
                    styles.NavItem,
                    activeItem && styles.ActiveNavItem
                  )}
                  key={index}
                >
                  <span>{nav}</span>
                </button>
              )
            })}
        </div>
        <div className={classNames(styles.RatingWrap)}>
          {ratingOptions.map((item, i) => {
            const activeRating = item === selectedRating
            return (
              <button
                key={`rating-${item}--${i}`}
                className={classNames(
                  styles.RatingItemIconWrap,
                  activeRating && styles.ActiveRatingItemIconWrap,
                  styles[`Rating-${item.toLowerCase()}`]
                )}
                onClick={() => {
                  if (toggleRatingInfo && selectedRating === item) {
                    setToggleRatingInfo(false)
                    setSelectedRating(null)
                  } else {
                    setToggleRatingInfo(true)
                    setSelectedRating(item)
                  }

                  setSelectedSubMenu(null)
                }}
                onKeyDown={handleKeyDown}
              >
                <Icon
                  className={styles.RatingItemIcon}
                  type={`rating-${item.toLowerCase()}`}
                  a11yText={`rating-${item.toLowerCase()}`}
                />
              </button>
            )
          })}
        </div>
      </div>
      <div className={styles.DesktopSelectedMenuContainer}>
        {selectedSubMenu && (
          <div className={classNames(styles.SelectedMenuWrap, styles.Desktop)}>
            {selectedSubMenu.items.map((groupItem, groupIndex) => (
              <div
                className={styles.SubNavMenuContainer}
                key={`groupItem--${groupIndex}`}
              >
                {groupItem.map((item, itemIndex) => (
                  <a
                    onKeyDown={handleKeyDown}
                    className={classNames(
                      styles.SubNavItem,
                      item.displayType === 'header' && styles.HeaderItem,
                      item.type === 'label' && styles.LabelItem,
                      item.type.includes('Link') && styles.LinkItem,
                      item.type === 'section' && styles.SectionItem
                    )}
                    href={item.ctaLink}
                    key={`item--${itemIndex}`}
                  >
                    {item.ctaLabel}
                  </a>
                ))}
              </div>
            ))}
            {selectedSubMenu.featuredItems &&
              selectedSubMenu.featuredItems.length > 0 && (
                <div className={styles.FeaturedItems}>
                  {selectedSubMenu.featuredItems.map((item, index) => (
                    <a
                      className={classNames(
                        styles.FeaturedItemWrap,
                        item.bgImage && styles.FeaturedItemWrapWithOverlay
                      )}
                      href={item.ctaLink}
                      style={
                        item.bgImage && {
                          backgroundImage: `url(${item.bgImage})`
                        }
                      }
                      key={`featuredItem--${index}`}
                    >
                      <span role="img" aria-label={item.alt || item.title} />
                      <h4>{item.title}</h4>
                      {item.ctaLabel && <span>{item.ctaLabel}</span>}
                    </a>
                  ))}
                </div>
              )}
          </div>
        )}
        {selectedRating && (
          <div
            className={classNames(styles.SelectedRatingWrap, styles.Desktop)}
          >
            <RatingContextProvider rating={selectedRating}>
              <RatingsDetail
                rating={selectedRating}
                closeHandler={() => {
                  setToggleRatingInfo(false)
                  setSelectedRating(null)
                }}
                layoutV2
              />
            </RatingContextProvider>
          </div>
        )}
      </div>
    </>
  )
}
DesktopNav.propTypes = {
  data: array,
  selectedRating: string,
  selectedSubMenu: object,
  setToggleRatingInfo: func,
  setSelectedRating: func,
  setSelectedSubMenu: func,
  handleKeyDown: func,
  toggleRatingInfo: bool
}
export default DesktopNav
