import React from 'react'
import { bool, object, func } from 'prop-types'
import { useThemeContext } from '@/component/Context/ThemeContext'

import BBFCNavigation from './component/BBFCNavigation'
import CBBFCNavigation from './component/CBBFCNavigation'

const Navigation = ({ isActive, user, instance, onClick }) => {
  const theme = useThemeContext()
  const componentMap = {
    bbfc: (
      <BBFCNavigation
        isActive={isActive}
        user={user}
        instance={instance}
        onClick={onClick}
      />
    ),
    cbbfc: (
      <CBBFCNavigation
        isActive={isActive}
        user={user}
        instance={instance}
        onClick={onClick}
      />
    )
  }

  return componentMap[theme]
}

Navigation.propTypes = {
  isActive: bool,
  user: object,
  instance: object,
  onClick: func
}

export default Navigation
