import React from 'react'
import classNames from 'classnames'
import Footer from '../Footer'
import Icon from '@/component/Primitive/Icon'
import Container from '@/component/Primitive/Container'

import styles from '../theme/CBBFCFooter.module.scss'

const CBBFCFooter = () => (
  <>
    <Container gutter className={styles.IconWrapper}>
      <Icon
        className={classNames(styles.FamilyIcon, styles.left)}
        type="family"
        width={100}
        a11yText="Big Family Icon"
      />
      <Icon
        className={classNames(styles.FamilyIcon, styles.right)}
        type="family"
        width={60}
        a11yText="Small Family Icon"
      />
    </Container>
    <Footer styles={styles} />
  </>
)

export default CBBFCFooter
