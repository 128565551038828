const createSearchItems = () => ({
  items: {
    topHits: [
      {
        value: 'Spider-Man: Far From Home (2019)',
        rating: '12A',
        themes: ['moderate violence'],
        id: '123423',
        url: '/search?q=batman%20fun%20final%20dog%20horizon'
      },
      {
        value: 'Spider-Man: Into the Spider-Verse (2018)',
        rating: 'PG',
        themes: ['moderate violence', 'threat'],
        id: '123424',
        url: '/search-result-2'
      },
      {
        value: 'Spider-Man: Homecoming (2017)',
        themes: [
          'moderate fantasy violence',
          'threat',
          'occasional bloody moments'
        ],
        rating: '12A',
        id: '123425',
        url: '/search-result-3'
      }
    ],
    suggestions: [
      { value: 'Spider-man films', id: '123421' },
      { value: 'Spider-man cartoons', id: '123422' }
    ]
  }
})

export default createSearchItems
