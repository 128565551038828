export default {
  bbfc: [
    [
      {
        text: 'Media Centre',
        url: '/about-us/news'
      },

      {
        text: 'About the BBFC',
        url: '/about-us'
      },
      {
        text: 'Work for us',
        url: '/#tbc'
      },
      {
        text: 'Contact us',
        url: '/about-us/contact-us'
      }
    ],
    [
      { text: 'Accessibility', url: '/#tbc' },
      {
        text: 'Privacy policy ',
        url: '/privacy-statement'
      },
      { text: 'Cookies policy', url: '/#tbc' },
      {
        text: 'Terms & conditions',
        url: '/terms-and-conditions'
      }
    ]
  ],
  cbbfc: [
    [
      {
        text: 'Contact Us',
        url: '/contact-us'
      },
      {
        text: 'What is the BBFC?',
        url: '/what-is-the-bbfc'
      }
    ],
    [
      {
        text: 'Resources',
        url: '/resources'
      },
      {
        text: 'What we do',
        url: '/what-we-do'
      }
    ],
    [
      {
        text: 'Film Ratings',
        url: '/film-ratings'
      },
      {
        text: 'Privacy Statement',
        url: '/privacy-statement'
      }
    ]
  ]
}
