// NEVER PUT SECRETS IN THIS FILE

const config = {
  defaults: {
    sentryDsn: 'https://45c42f69f32b4328bd23f76a306846c2@sentry.io/1818736',
    gtmId: 'GTM-N7XNRNL',
    meta: {
      titleTemplate: `%s | BBFC`,
      twitter: {
        cardType: 'summary_large_image',
        site: '@BBFC'
      },
      googleSiteVerification: null,
      locale: 'en_GB',
      themeColor: '#1565c0',
      title: 'BBFC',
      twitterHandle: '@BBFC'
    },
    production: {
      gtmId: 'GTM-M2HHPBD'
    }
  }
}

const createConfig = () => {
  const env = process.env.NODE_ENV
  return { ...config.defaults, ...config[env] }
}

export default createConfig
