const createQuery = () => `
query User($url: String!) {
  instance(url: $url) {
    theme
    _id
    googleTagManagerId
    name
    navigation {
        id
        title
        type
        displayType
        section {
          fullUrlPath
        }
        url
        linkText
        images {
          thumbnail_3x2_438 {
            url
          }
        }
        parent
        order
      }
  }
  user {
    _id
    name
    email
  }
}`

export default createQuery
