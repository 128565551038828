import React from 'react'
import { bool, object, func } from 'prop-types'
import classNames from 'classnames'

// import UserButton from '@/component/Common/UserButton'
import SmartLink from '@/component/Primitive/SmartLink'
import Surround from '@/component/Primitive/Surround'

import styles from '../theme/CBBFCNavigation.module.scss'

const Navigation = ({ isActive, user, instance, onClick }) => (
  <div className={classNames(styles.Navigation, isActive && styles.isActive)}>
    <Surround className={styles.NavigationSurround} solid rounded shadow>
      <SmartLink
        className={classNames(styles.NavigationLink, styles.bold)}
        to="/section"
        as="/what-we-do"
        onClick={onClick}
      >
        What we do
      </SmartLink>
      <SmartLink
        className={classNames(styles.NavigationLink, styles.bold)}
        to="/section"
        as="/film-ratings"
        onClick={onClick}
      >
        Ratings
      </SmartLink>
      <SmartLink
        className={classNames(styles.NavigationLink, styles.bold)}
        to="/section"
        as="/resources"
        onClick={onClick}
      >
        Resources
      </SmartLink>
      {/* <UserButton user={user} instance={instance} /> */}
    </Surround>
  </div>
)

Navigation.propTypes = {
  isActive: bool,
  user: object,
  instance: object,
  onClick: func
}

export default Navigation
