import React from 'react'
import { func, string, object } from 'prop-types'
import classNames from 'classnames'
import searchItems from '@/fixture/search'

import SmartLink from '@/component/Primitive/SmartLink'
import Icon from '@/component/Primitive/Icon'
import IconButton from '@/component/Primitive/IconButton'
import RatingsDetail from './RatingsDetail'
import Search from '@/component/Common/Search'

import Navigation from '@/component/Common/Navigation'

import { displayRatings } from '../../../../../../data/ratings.json'
import styles from '../theme/CBBFCHeader.module.scss'

import { useDarkContext } from '@/component/Context/DarkContext'

const Header = ({ handleActiveToggle, activeItem, instance, user }) => {
  const isDark = useDarkContext()

  return (
    <>
      <div
        className={(styles.HeaderWrapper, isDark ? styles.isDark : undefined)}
      >
        <header
          role="banner"
          className={classNames(styles.Header, activeItem && styles.isActive)}
        >
          <div className={styles.SiteTitle}>
            <SmartLink to="/section" as="/">
              <h1>
                <div className={styles.LogoWrapper}>
                  <Icon
                    className={styles.Logo}
                    a11yText="CBBFC Logo"
                    type="cbbfc-logo--strapline"
                  />
                </div>
                <div className={styles.TabletLogoWrapper}>
                  <Icon
                    className={classNames(styles.TabletLogo, styles.Logo)}
                    a11yText="CBBFC Logo"
                    type="cbbfc-logo--white"
                  />
                </div>
              </h1>
            </SmartLink>
          </div>
          <div
            className={classNames(
              styles.SearchWrapper,
              activeItem === 'search' && styles.isActive
            )}
          >
            <Search {...searchItems()} />
          </div>
          <div className={styles.NavigationActions}>
            <IconButton
              className={classNames(styles.HeaderToggle, styles.SearchToggle)}
              icon={activeItem === 'search' ? 'close' : 'search'}
              onClick={() => handleActiveToggle('search')}
              a11yText={
                activeItem === 'search' ? 'Hide search' : 'Reveal search'
              }
            />
            <IconButton
              className={styles.HeaderToggle}
              icon={activeItem === 'nav' ? 'close' : 'menu'}
              onClick={() => handleActiveToggle('nav')}
              a11yText={
                activeItem === 'nav' ? 'Hide navigation' : 'Reveal navigation'
              }
            />
          </div>
          <Navigation
            isActive={activeItem === 'nav'}
            instance={instance}
            user={user}
            onClick={() => handleActiveToggle('')}
          />
          {activeItem === 'all' && (
            <div className={styles.DetailsWrapper}>
              {displayRatings.map((rating, i) => (
                <RatingsDetail
                  key={i}
                  rating={rating}
                  closeHandler={() => handleActiveToggle('')}
                />
              ))}
            </div>
          )}
        </header>
      </div>
      <Icon
        type="green-triangle"
        className={styles.Triangle}
        a11yText="Green Triangle"
      />
    </>
  )
}

Header.propTypes = {
  handleActiveToggle: func,
  activeItem: string,
  instance: object,
  user: object
}

export default Header
