import React from 'react'
import { number } from 'prop-types'
import Link from 'next/link'

import Container from '@/component/Primitive/Container'
import SpacedList from '@/component/Primitive/SpacedList'
import Type from '@/component/Primitive/Type'
import Prose from '@/component/Primitive/Prose'
import createQuery from '../src/query/user/get-user'
import retrieveData from '../src/lib/retrieve-data'

const ErrorPage = ({ statusCode }) => (
  <SpacedList>
    <Container size="medium" gutter center>
      <Prose>
        <Type size="title">
          {statusCode
            ? `An error occurred on server (${statusCode})`
            : 'An error occurred on client'}
        </Type>
        <p>
          Why not try heading back to the{' '}
          <Link href="/section" as="/">
            <a href="/">homepage</a>
          </Link>
          .
        </p>
      </Prose>
    </Container>
  </SpacedList>
)

ErrorPage.getInitialProps = async (context) => {
  const { req, res, err } = context
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  const query = createQuery()
  const { data } = await retrieveData(context, query)
  return { statusCode, instance: req.instance, ...data }
}

ErrorPage.propTypes = {
  statusCode: number
}

export default ErrorPage
