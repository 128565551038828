import React from 'react'
import { number } from 'prop-types'
import { useTransition, animated, config } from 'react-spring/web.cjs'

import AgeRating from '@/component/Primitive/AgeRating'

import { displayRatings } from '../../../../../data/ratings.json'
import styles from './AgeRatingCarousel.module.scss'

const AgeRatingCarousel = ({ interval }) => {
  const [index, setCurrent] = React.useState(0)
  const transition = useTransition(displayRatings[index], {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrent((prevState) =>
        prevState < displayRatings.length - 1 ? prevState + 1 : 0
      )
    }, interval)
    return () => clearInterval(timer)
  }, [interval])

  return (
    <div className={styles.AgeRatingCarousel}>
      {transition((values, item) => (
        <animated.div className={styles.AgeRating} style={values}>
          <AgeRating size={0} rating={item} />
        </animated.div>
      ))}
    </div>
  )
}

AgeRatingCarousel.defaultProps = {
  interval: 3000
}

AgeRatingCarousel.propTypes = {
  interval: number
}

export default AgeRatingCarousel
