import React, { useEffect, useRef } from 'react'
import { string, func, bool, number, array } from 'prop-types'
import classnames from 'classnames'

import TextControl from '@/component/Primitive/TextControl'
import IconButton from '@/component/Primitive/IconButton'
import List from '@/component/Primitive/List'
import ListItem from './ListItem'
import VisuallyHidden from '@/component/Primitive/VisuallyHidden'

import styles from '../theme/BBFCSearch.module.scss'

const Search = ({
  icon,
  onSubmit,
  searchTerm,
  onSearchTermChange,
  results,
  setHovered,
  activeListItemIndex,
  isOpen,
  designV2,
  modalStyle,
  desktopStyle
}) => {
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <div
      className={classnames(
        styles.Search,
        designV2 && styles.SearchV2,
        modalStyle && styles.ModalStyle,
        desktopStyle && styles.DesktopStyle
      )}
    >
      {designV2 ? (
        <div
          className={classnames(
            styles.SearchBar,
            desktopStyle && styles.DesktopStyle
          )}
        >
          <input
            ref={inputRef}
            placeholder="Find films, shows, age ratings and more"
            className={styles.SearchInput}
            id="search-elem"
            name="Search"
            type="search"
            autoComplete="off"
            aria-labelledby="search-elem search-button"
            value={searchTerm}
            onChange={onSearchTermChange}
          />
          <IconButton
            className={styles.SearchInputIcon}
            icon="search"
            a11yText="Search"
            onClick={onSubmit}
          />
        </div>
      ) : (
        <form onSubmit={onSubmit}>
          <div className={styles.SearchFieldWrapper}>
            <VisuallyHidden>
              <label id="search-elem" htmlFor="search">
                Search
              </label>
            </VisuallyHidden>
            <TextControl
              className={styles.SearchField}
              placeholder="Find films, age ratings, and more..."
              id="search-elem"
              name="Search"
              type="search"
              autoComplete="off"
              aria-labelledby="search-elem search-button"
              value={searchTerm}
              onChange={onSearchTermChange}
            />

            <IconButton
              className={styles.SearchButton}
              id="search-button"
              aria-haspopup="listbox"
              aria-labelledby="search-elem search-button"
              iconWidth={24}
              icon={icon}
              a11yText="Search"
              onClick={onSubmit}
              iconClassName={styles.SearchIcon}
            />
          </div>
        </form>
      )}

      <div
        className={classnames(
          styles.SearchSuggestionsWrapper,
          searchTerm.length && isOpen && results.length && styles.visible,
          designV2 && styles.SearchSuggestionsWrapperV2,
          desktopStyle && styles.SearchSuggestionDesktopStyle
        )}
        aria-labelledby="search-elem"
      >
        <p className={styles.ListLabel}>
          Top results - press enter for full list
        </p>
        {results.length > 0 && (
          <List
            className={styles.List}
            aria-labelledby="search-elem"
            tabIndex="-1"
            role="search"
            unstyled
          >
            {results.map((item, i) => (
              <ListItem
                key={`searchItem-${i}`}
                isActive={i === activeListItemIndex}
                role="option"
                item={item}
                onMouseEnter={() => setHovered(i)}
                onMouseLeave={() => setHovered(undefined)}
                onClick={onSubmit}
                designV2={designV2}
              />
            ))}
          </List>
        )}
      </div>
    </div>
  )
}

Search.propTypes = {
  searchTerm: string,
  results: array,
  onSubmit: func,
  onSearchTermChange: func,
  setHovered: func,
  activeListItemIndex: number,
  isOpen: bool,
  icon: string,
  designV2: bool,
  modalStyle: bool,
  desktopStyle: bool
}

export default Search
